export const baseURL="http://www.worldpeace.live"
export const baseURL1="http://www.worldpeace.live"


// 腾讯对象存储cos
export const SECRETID = '';
export const SECRETKEY = '';
export const BUCKET = '';
export const REGION = '';
export const FOLDER = '';
