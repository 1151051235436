import axios from "axios";
import {baseURL} from "./my-account";
import { def } from "@vue/shared";

const http = axios.create({
    baseURL,
    timeout: 3000,
});

// 请求拦截器
http.interceptors .request .use((config) => {
    return config;
});
//响应拦截器
http.interceptors .response .use((response) => {
    return response .data;
});
export default {
    get(ur1, params) {
        return http.get(ur1, { params } );
        },
    post(ur1, data) {
        return http.post(ur1, data);
    },
    };
